<template>
  <transition name="fade">
    <div
      class="flex flex-col list-view-item h-full rounded-lg"
      v-if="showList"
    >
      <div class="w-full cursor-pointer flex justify-center product-image mt-4">
        <img
          @click="navigate_to_detail_view"
          class="block product-image-list object-contain w-full"
          v-if="item.images && item.images.length > 0"
          :src="item.images[0].image"
        />
      </div>

      <div class="flex flex-col h-full justify-between">
        <!-- product -->
        <div class="flex flex-col justify-center px-4 lg:p-3 w-full">
          <div
            v-if="item.subDescription"
            v-html="item.subDescription"
            class="w-full text-white sub-description px-px rounded"
            style="background-color: #0e433380"
          />
          <div v-else>
            <div class="mt-6"></div>
          </div>

          <div
            v-if="item.brand && item.brand.length && isBrandVisible(item.brand[0].brandName)"
            @click="navigate_to_brand_view(item.brand[0].brandName)"
            class="text-primary2 font-semibold hover:underline mt-5 mb-1 text-xs cursor-pointer"
          >
            {{ item.brand[0].brandName }}
          </div>
          <div v-else style="margin-top: 37px"></div>

          <vx-tooltip :text="item.name" position="bottom">
            <div
              @click="navigate_to_detail_view"
              class="product-name cursor-pointer hover:underline ellipsis-text"
            >
              {{ item.name }}
            </div>
          </vx-tooltip>

          <div
            class="price font-bold mt-3 mb-0"
            :class="item.compareAtPrice || item.listPrice ? 'mb-0' : 'mb-5'"
          >
            ${{ item.price | formatDecimals }}
          </div>

          <!-- price per measurement -->
          <div
            class="price-per-measurement text-primary2 mt-3 mb-3 text-xs"
            v-if="item.isPricePerMeasurementHidden"
          >
            ${{ measurementPrice }}
          </div>
          <!-- end of price per measurement -->

          <div
            class="rrp-price text-primary2 text-xs mb-4"
            v-if="!item.isRRPHidden"
          >
            RRP
            <span :class="item.isCompareAtPriceToggled && 'line-through'"
              >${{ item.compareAtPrice | formatDecimals }}</span
            >
          </div>

          <div v-show="!item.isListPriceHidden" class="text-primary2 mb-1 text-xs" v-if="item.listPrice">
            List Price
            <span :class="item.isListPriceToggled && 'line-through'"
              >${{ item.listPrice | formatDecimals }}</span
            >
          </div>
        </div>

        <!-- action -->
        <div class="w-full">

          <div class="w-full relative">

            <small class="block font-medium text-red-600 text-center lg:text-left mb-1 min-error-text px-4"> {{minMaxErrorText}} </small>

            <div
              class="flex flex-1 justify-center w-full px-4"
              v-if="(item.canBuy || superAdminCanAddToCart) && item.isAvailable"
            >
              <div
                v-if="inCart"
                class="w-full flex flex-row rounded-lg py-1 px-3 shop-button"
              >
                <button
                  class="w-2/12 flex text-white justify-center items-center text-2xl cursor-pointer"
                  @click="
                    decrease(
                      getCartInfo(item._id).quantity,
                      item,
                      item.inventory.requiredQuantity
                    )
                  "
                >
                  &minus;
                </button>

                <input
                  name="qty"
                  type="number"
                  min="item.inventory.requiredQuantity"
                  max="item.inventory.maxQuantity"
                  class="w-full mx-2 text-lg"
                  ref="qtyInput"
                  :value="cartProduct(item._id)"
                  @input="updateCartProduct($event, item)"
                  @blur="minMaxErrorText = ''"
                />

                <button
                  class="w-2/12 flex text-white justify-center items-center text-2xl cursor-pointer"
                  @click="increase(item)"
                >
                  &plus;
                </button>
              </div>

              <span
                v-if="item.free_shipping"
                class="text-grey flex items-start justify-center mt-1"
              >
                <feather-icon icon="ShoppingCartIcon" svgClasses="w-4 h-4" />
                <span class="text-sm ml-2">Free Shipping</span>
              </span>
            </div>
          </div>
          <slot name="action-buttons" :item2="item" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from "vuex";
import StoreHelper from "@/mixins/Store.vue";

export default {
  mixins: [StoreHelper],
  inject: ["getRoleRouteName"],
  data() {
    return {
      quantity: 1,
      showList: false,
      cartItems: [],
      minMaxErrorText: '',
      userTypeAdmin: undefined,
      discounts: [],
      clinic: null
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {
        return {
          inventory: {},
        };
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    inCart: {
      type: Boolean,
      default: false,
    },
    superAdminCanAddToCart: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getCartInfo() {
      return (itemId) =>
        this.$store.getters["eCommerce/getCartItemTest"](itemId);
    },
    isInCartTest() {
      return (itemId) => this.$store.getters["eCommerce/isInCartTest"](itemId);
    },
    isInCart() {
      return (itemId) => this.$store.getters["eCommerce/isInCart"](itemId);
    },
    isInWishList() {
      return (itemId) => this.$store.getters["eCommerce/isInWishList"](itemId);
    },
    cartProduct() {
      return (itemId) => this.$store.getters["eCommerce/totalItemQuantityIncart"](itemId);
    },
    measurementPrice() {
      const price = (this.item.price / this.item.pricePerMeasurement.price).toFixed(2)
      return `${price} ${this.item.pricePerMeasurement.unit}`
    },
  },
  watch: {
    "item.inventory": function () {
      if (
        this.item.inventory.requiredQuantity !== undefined &&
        this.item.inventory.requiredQuantity !== 0
      ) {
        this.quantity = this.item.inventory.requiredQuantity;
        this.item.selectedQuantity = this.item.inventory.requiredQuantity;
      } else {
        this.quantity = 1;
        this.item.inventory.requiredQuantity = 1;
        this.item.selectedQuantity = 1;
      }
    },
    "$store.state.eCommerce.cartItems": {
      handler(val) {
        this.cartItems = JSON.parse(JSON.stringify(val.slice().reverse()));
      },
      immediate: true,
    },
    "$store.state.clinicId": function (val) {
      this.fetchClinic()
    },
  },

  methods: {
    ...mapActions("clinic", ["fetchClinicsDetail"]),
    removeFromCart(item, carItem) {
      let prod = {
        data: item,
        qty: 0
      };
      this.$store.commit("eCommerce/UPDATE_ITEM_QUANTITY_TEST", prod);
      this.$store.dispatch("eCommerce/toggleItemInCart", carItem);
      this.minErrorText = false
    },
    updateCartProduct(e, item) {
      const carItem = this.getCartInfo(item._id)
      const value   = parseInt(e.target.value)
      const minQty  = item.inventory.requiredQuantity;
      const maxQty  = item.inventory.maxQuantity;
      let qty = value


      if (this.timeout) clearTimeout(this.timeout)

      this.timeout = setTimeout(() => {

          if (value < minQty || value < 1 || isNaN(value) || value == '') {
            this.minMaxErrorText = `Minimum quantity is ${minQty}.`
            e.target.value = minQty;
            qty = minQty
          } else if (maxQty > 0 && value > maxQty) {
            this.minMaxErrorText = `Maximum quantity is ${maxQty}.`
            e.target.value = maxQty;
            qty = maxQty
          } else {
            this.minMaxErrorText = ''
            e.target.value = qty;
          }

          let prod = {
            data: item,
            qty: qty
          };

          this.$store.commit("eCommerce/UPDATE_ITEM_QUANTITY_TEST", prod);

          this.$store.dispatch("eCommerce/updateItemQuantity", {
            item: carItem

          }).then((response) => {

            if (response.message !== 'success') {
              this.$vs.notify({
                color: 'danger',
                title: `Product Not Updated`,
                text: `We are having issues updating your cart. Please try again.`
              });
            }
          });

     }, 1000);

    },
    removeItemFromCart(item) {
      this.$store.dispatch("eCommerce/toggleItemInCart", item);
    },
    toggleItemInWishList(item) {
      this.$store.dispatch("eCommerce/toggleItemInWishList", item);
    },
    increase(data) {
      this.selectedChangeQuantity(1, data, "increment");
    },
    decrease(quantity, data, requiredQuantity) {
      let cartItem = this.getCartInfo(data._id);
      let cartQty = cartItem.quantity;

      if (quantity - 1 >= requiredQuantity) {
        this.quantity = quantity - 1;
        this.selectedChangeQuantity(quantity, data, "decrement");
      } else {
        this.removeItemFromCart(cartItem);
      }
    },

    selectedChangeQuantity(quantity, data, type) {
      this.$emit("quantityChange", {
        index: this.index,
        quantity: this.quantity,
      });

      this.errors.removeById(data._id);
      this.item.selectedQuantity = quantity;
      this.additemInCart(data, type);
    },
    additemInCart(data, type) {
      let item = { data: data, type: type };
      let messageType =
        type === "decrement" ? "removed from your cart" : "added to your cart";

      this.$vs.loading();
      this.$store.dispatch("eCommerce/additemInCart", item).then((response) => {
        if (response.status === 200 && response.data.message === "success") {
          this.$vs.notify({
            color: "success",
            title: `Product ${messageType}`,
            text: `Product successfully ${messageType}.`,
          });
        } else {
          this.$vs.notify({
            color: "danger",
            title: `Product Not Updated`,
            text: `We are having issues updating your cart. Please try again.`,
          });
        }
        this.$vs.loading.close();
      });
    },
    cartButtonClicked(item) {
       // Unused? 
    },
    navigate_to_detail_view() {
      let routername;

      if(this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routername = "SuperAdminProductDetail"

      } else {
        routername = this.getRoleRouteName("ProductDetail")
      }

      this.$router
        .push({
          name: routername,
          params: {
            id: this.item._id,
          },
        })
        .catch(() => {});
    },
    navigate_to_brand_view(brand) {
      let routername;
      const formattedBrandName = brand
        ? brand.toLowerCase().replace(/\s+/g, "-")
        : null;

      if(this.userTypeAdmin && this.userTypeAdmin === "superAdmin") {
        routername = "SuperAdminOrderCenterBrandPage"

      } else {
        routername = this.getRoleRouteName("OrderCenterBrandPage")
      }

      this.$router.push({
        name: routername,
        params: {
          slug: formattedBrandName,
        },
        query: {
            page: 1
        }
      });
    },
    async fetchClinic() {
      this.clinic = await this.fetchClinicsDetail(sessionStorage.getItem("doctorClinicSelectedClinic"));
    }
  },
  
  async created() {
    if (this.item.inventory) {
      if (
        this.item.inventory.requiredQuantity !== undefined &&
        this.item.inventory.requiredQuantity !== 0
      ) {
        this.quantity = this.item.inventory.requiredQuantity;
      } else {
        this.quantity = 1;
        this.item.inventory.requiredQuantity = 1;
      }
    }

    this.userTypeAdmin = this.$store.state.AppActiveUser.userType
    this.item.selectedQuantity = this.quantity;
    this.fetchClinic()
  },
  mounted() {
    setTimeout(() => {
      this.showList = true;
    }, 500);
  },
};
</script>

<style lang="scss" scoped>
.sub-description {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 233.333% */
  background-color: #0e433380;
}

.product-name {
  color: #0e4333;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px; /* 175% */
  min-height: 42px;
}
.price {
  color: #0e4333;
  font-size: 14px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 21px; /* 150% */
}
.ellipsis-text {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.filler {
  margin: 1.2rem 0;
}

.product {
  &-image {
    min-height: 130px;
  }
  &-image-list {
    border-radius: 1.5rem;
    border: none;
    max-width: 130px;
    max-height: 130px;
  }
}

.script-amount {
  p {
    strong {
      font-weight: 400;
      text-transform: capitalize;
    }
  }
}



.shop-button{
  background-color: #0E4333;

  button{
    background-color: #fff;
    background: transparent;
    -webkit-appearance: none;
    border: none;
    width: 100%;
    transition: transform 200ms linear;

    i {
      font-size: 1.2rem;
    }

    &:hover{
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &:active {
      transform: scale(1.1);
      transform-origin: center;
    }
  }

  input[type=number] {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    border: none !important;
    background-color: white;
    text-align: center;
    border-radius: .2rem;
    padding: 0.35rem 0;

    &:focus {
      box-shadow: none;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

</style>
